<template>
  <div class="modal-card" style="width: auto">
    <div class="modal-card-body">
      <b-loading :active.sync="loading" is-full-page />
      <span class="buttons">
        <button class="button is-warning is-outlined is-small" @click="close">
          <b-icon icon="chevron-left" />
          <span>{{ $t('global.back') }}</span>
        </button>
      </span>
      <ValidationObserver
        ref="observer"
        v-slot="{ formValid }"
        tag="form"
        @submit.prevent="submit"
      >
        <b-field :label="$t('pages.pickup_management.collect_creation.comments1')">
          <ValidationProvider>
            <textarea v-model="comments1" class="input" />
          </ValidationProvider>
        </b-field>
        <b-field :label="$t('pages.pickup_management.collect_creation.comments2')">
          <ValidationProvider>
            <textarea v-model="comments2" class="input" />
          </ValidationProvider>
        </b-field>
        <div v-if="endusersData && endusersData.length>0" class="d-row">
          <div v-for="enduser in endusersData" :key="enduser.enduserCode" class="container-card card">
            <div class="card-header centered-items">
              <h2 class="subtitle m">
                {{ enduser.enduserCode }} - {{ enduser.enduserName }}
              </h2>
              <span v-if="enduser.theoricalStock || enduser.theoricalStock === 0" class="has-text-primary">{{ $t('pages.pickup_management.collect_creation.theorical_stock') }} : {{ enduser.theoricalStock }}</span>
              <span class="has-text-warning">{{ $t('pages.pickup_management.collect_creation.weight') }} : {{ calcWeight(enduser) }} KG</span>
            </div>
            <div class="container card-content">
              <div class="d-flex-column">
                <b-field id="calendar-step-cc-load-date" :label="$t('pages.pickup_management.collect_creation.available_date')">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="date"
                  >
                    <b-datepicker
                      v-model="enduser.loadingDate"
                      name="date"
                      icon="calendar-alt"
                      :placeholder="$t('global.click_to_select')"
                      :date-formatter="(date) => $dayjs(date).format('DD/MM/YYYY')"
                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                <div v-if="enduser.lastInventory && enduser.lastInventory.length>0" class="card has-text-centered content-card">
                  <div class="card-header">
                    <span class="card-header-title">{{ $t('pages.pickup_management.collect_creation.last_inventory') }}</span>
                  </div>
                  <div class="card-content">
                    <span class="subtitle">
                      {{ $dayjs(enduser.lastDeclaration.date).format('DD-MM-YYYY') }}
                    </span>
                    <div class="separator" />
                    <div v-for="(row, index) in enduser.lastInventory" :key="index">
                      <span>
                        {{ row.material }} :
                      </span>
                      <span>
                        {{ row.quantity }}
                      </span>
                    </div>
                    <div class="separator" />
                    <span class="subtitle">{{ $t('pages.pickup_management.collect_creation.comments') }} :</span>
                    <div>
                      {{ enduser.lastDeclaration.comments }}
                    </div>
                  </div>
                </div>
                <div v-if="enduser.filteredClosures && enduser.filteredClosures.length > 0" class="card has-text-centered content-card">
                  <div class="card-header">
                    <span class="card-header-title">{{ $t('pages.pickup_management.collect_creation.closures') }}</span>
                  </div>
                  <div class="card-content">
                    <div v-for="c of enduser.filteredClosures" :key="c.id">
                      {{ $dayjs(c.startDate).format('DD/MM/YYYY') }}
                      <b-icon icon="arrow-right" class="is-small" />
                      {{ $dayjs(c.endDate).format('DD/MM/YYYY') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-row">
                <div v-for="(mat, index) in enduser.mats" id="matsDisplay" :key="index">
                  <span class="m">Mat : <span class="has-text-primary subtitle">{{ mat.mat.MATNR }}</span> </span>
                  <span class="m">|</span>
                  <span class="m">Quantity : <span class="has-text-primary subtitle">{{ mat.qty }}</span></span>
                  <span>
                    <b-button
                      icon-left="trash"
                      size="is-small"
                      @click="() => {enduser.mats.splice(index,1); $forceUpdate()}"
                    />
                  </span>
                </div>
                <div class="form ">
                  <div v-if="enduser">
                    <b-field v-if="enduser.newMat" label="Adding material">
                      <div class="container">
                        <ValidationProvider v-slot="{ errors }" class="m-r">
                          <span>
                            <multiselect
                              v-if="enduser && enduser.materials"
                              id="calendar-step-cc-material-choose"
                              v-model="enduser.newMat.mat"
                              :options="enduser.materials"
                              :multiple="false"
                              :close-on-select="true"
                              :placeholder="$t('pages.pickup_management.collect_creation.select_material')"
                              label="MATNR"
                              track-by="MATNR"
                            />
                          </span>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" :rules="{required: true, dynamic_max_value:{enduser:enduser, selectMat: enduser.newMat.mat}}" class="m-r" name="quantity">
                          <span>
                            <b-input
                              id="calendar-step-cc-qty"
                              v-model="enduser.newMat.qty"
                              type="text"
                              pattern="[a-zA-Z0-9 ]+"
                              name="quantity"
                            />
                          </span>
                          <span class="container has-text-danger" :style="{maxWidth: '200px'}">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <b-button class="m-r" @click="()=> {enduser.newMat = null; $forceUpdate()}">
                          {{ $t('global.cancel') }}
                        </b-button>
                        <b-button id="calendar-step-cc-add" type="is-primary" @click="submitAddMat(enduser)">
                          {{ $t('global.add') }}
                        </b-button>
                      </div>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="enduser.mats.length <5" class="center">
              <b-button
                id="calendar-step-cc-materials"
                type="is-info"
                icon-left="plus"
                :disabled="enduser.newMat"
                @click="addMat(enduser)"
              >
                {{ $t('pages.pickup_management.collect_creation.add_material') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="submit-section">
          <button
            id="calendar-step-cc-submit"
            class="is-primary button"
            type="submit"
            :disabled="endusersData.find(d => d.newMat)"
          >
            {{ $t('global.submit') }}
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>

import enduserDeclarationAdmin from '@/services/v2/enduserDeclarationAdmin'
import Collect from '@/services/v2/collect'
import { mapActions } from 'vuex'

import { extend } from 'vee-validate'

export default {
  components: {

  },
  props: {
    endusers: {
      type: Array,
      required: true
    },
    selectedGroup: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      endusersData: [],
      // loadingDate: null,
      comments1: null,
      comments2: null,
      loading: false
    }
  },
  computed: {

  },
  mounted: function () {
    try {
      this.loadData()
      this.keepFutureClosures()
    } catch (error) {
      this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
    }
  },
  created () {
    extend('dynamic_max_value', {
      validate (value, { enduser, selectMat }) {
        let maxVal
        const materialRow = enduser.lastInventory.find(inventory => inventory.material === selectMat.MATNR)
        maxVal = materialRow ? materialRow.quantity : 1000
        return parseFloat(value) <= maxVal
      },
      params: ['enduser', 'selectMat'],
      message: 'Exceeds the maximum collectable quantity, please declare inventory first. '
    })
  },
  methods: {
    ...mapActions(['archiveAction']),
    loadData: async function () {
      this.loading = true
      if (this.endusers) {
        for (let i = 0; i < this.endusers.length; i++) {
          const e = this.endusers[i]
          const lastDeclaration = await enduserDeclarationAdmin.getDeclarationWithFilters({ enduserCode: e.enduserCode, last: true })
          let lastInventory
          if (lastDeclaration) {
            lastInventory = await enduserDeclarationAdmin.getQuantities(lastDeclaration.id)
          }
          const resultMaterials = await enduserDeclarationAdmin.getMaterials(e.enduserCode)
          const materials = []

          resultMaterials.forEach(m => {
            if (m.MATNR === 'GPS52') {
              materials.push({
                'MATNR': 'GPS52',
                'NTGEW': m.NTGEW
              },
              {
                'MATNR': 'GPS55',
                'NTGEW': m.NTGEW
              })
            } else {
              materials.push({
                'MATNR': m.MATNR,
                'NTGEW': m.NTGEW
              })
            }
          })

          this.endusersData.push({
            ...e,
            lastDeclaration: lastDeclaration,
            lastInventory: lastInventory,
            materials: materials,
            newMat: null,
            mats: []
          })
        }
      }
      this.loading = false
    },
    keepFutureClosures: function () {
      this.endusers.forEach(d => {
        d.filteredClosures = d.closures?.filter(c => {
          return this.$dayjs(c.endDate).isAfter(this.$dayjs())
        })
      })
    },
    close () {
      this.$emit('close')
      this.$emit('nextTourStep', 'submitCollect')
    },
    submit: async function () {
      try {
        if (await this.$refs.observer.validate()) {
          let data = []
          const collects = []

          this.$buefy.dialog.confirm({
            title: this.$t('pages.pickup_management.collect_creation.confirm.title'),
            message: this.$t('pages.pickup_management.collect_creation.confirm.message'),
            confirmText: this.$t('pages.pickup_management.collect_creation.confirm.confirm'),
            cancelText: this.$t('pages.pickup_management.collect_creation.confirm.cancel'),
            type: 'is-link',
            hasIcon: true,
            onConfirm: async () => {
              try {
                // if (this.$dayjs(this.loadingDate).isBefore(this.$dayjs().hour(0).minute(0).second(0).subtract(1, 'seconds'))) {
                //   throw Error(this.$t('pages.pickup_management.collect_creation.errors.date_in_past'))
                // }
                this.endusersData.map(e => {
                  data = []
                  if (e.mats.length === 0) {
                    throw Error(this.$t('pages.pickup_management.collect_creation.errors.no_material'))
                  }
                  e.mats.map(m => {
                    let sameMat = e.lastInventory ? e.lastInventory.find(i => {
                      return i.material === m.mat.MATNR
                    }) : null
                    let invQty = sameMat ? sameMat.quantity : null
                    data.push({
                      mat: m.mat.MATNR,
                      qty: m.qty,
                      inv: invQty
                    })
                  })
                  collects.push({
                    enduserCode: e.enduserCode,
                    loadingDate: this.$dayjs(e.loadingDate).utc(true),
                    dateCreate: this.$dayjs(),
                    invDate: e.lastDeclaration.date,
                    text1: this.comments1,
                    text2: this.comments2,
                    group: this.selectedGroup,
                    data: [...data]
                  })
                })
                const call = await Collect.insertCollect(collects)
                this.$buefy.toast.open({ message: call, type: 'is-primary' })
                this.close()
                collects.map(c => {
                  this.archiveAction(`[Pickup management] Added a collect ${c.enduserCode}`)
                })
              } catch (error) {
                this.$buefy.toast.open({ message: error.message || error, type: 'is-danger', duration: 5000 })
              }
            }
          })
          this.$emit('nextTourStep', 'submitCollect')
        }
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    addMat: function (enduser) {
      enduser.newMat = {
        mat: null,
        qty: null
      }
      this.$emit('nextTourStep', 'addMaterial')
      this.$forceUpdate()
    },
    submitAddMat: async function (enduserData) {
      try {
        const isValidate = await this.$refs.observer.validate()
        if (!isValidate) return
        if (enduserData.mats.length < 5) {
          if (enduserData.newMat.mat && enduserData.newMat.qty) {
            if (enduserData.mats.find(m => {
              return m.mat.MATNR === enduserData.newMat.mat.MATNR
            })) {
              throw Error(this.$t('pages.pickup_management.collect_creation.errors.existing_material'))
            }
            enduserData.mats.push(enduserData.newMat)
            enduserData.newMat = null

            this.$forceUpdate()
          } else {
            throw Error(this.$t('pages.pickup_management.collect_creation.errors.empty_fields'))
          }
        } else {
          throw Error(this.$t('pages.pickup_management.collect_creation.errors.only_5_materials'))
        }
        this.$emit('nextTourStep', 'confirmMaterial')
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    calcWeight: function (enduser) {
      let sum = 0
      if (enduser.mats) {
        enduser.mats.map(m => {
          sum += m.mat.NTGEW * m.qty
        })
      }
      return sum
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-around;
}

.separator{
  height: 2px;
  background-color: #ced6e0;
  margin: 5px;
}

.content-card {
  width: 350px;
  margin-bottom: 1rem;
}

.container-card {
  margin: 1rem;
  min-width: 900px;
}

.form
{
  margin-left: 2rem;
}

.m-r{
  margin-right: 10px;
}
.submit-section {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.centered-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.flex-row {
  display: flex;
  flex-direction: column;
}

#matsDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m {
  margin: 1rem;
}
</style>
