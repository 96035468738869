<template>
  <section class="section">
    <b-modal v-if="dynamicFields[editIndex]" v-model="isModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <b-field label="Name">
            <b-input v-model="dynamicFields[editIndex].name" />
          </b-field>
          <b-field label="Display name">
            <b-input v-model="dynamicFields[editIndex].displayName" />
          </b-field>
          <b-field label="Translate ref">
            <b-input v-model="dynamicFields[editIndex].translateRef" />
          </b-field>
          <b-field label="Type">
            <b-select v-model="dynamicFields[editIndex].type">
              <option v-for="(el, i) in types" :key="i" :value="el">
                {{ el }}
              </option>
            </b-select>
          </b-field>
          <div v-if="dynamicFields[editIndex].type === 'select'">
            <div class="columns ">
              <div class="column">
                <b-field label="All Values" />
              </div>
              <div class="column" style="text-align: end;">
                <b-button type="is-primary" @click="dynamicFields[editIndex].selectFields.push({fieldId: dynamicFields[editIndex].fieldId})">
                  Add
                </b-button>
              </div>
            </div>
            <div v-for="(el, i) in dynamicFields[editIndex].selectFields" :key="i" class="columns">
              <b-input v-model="dynamicFields[editIndex].selectFields[i].label" class="column" />
              <b-input v-model="dynamicFields[editIndex].selectFields[i].value" class="column" />
              <div class="column" style="text-align: end;">
                <b-button type="is-danger" @click="dynamicFields[editIndex].selectFields.splice(i, 1)">
                  RM
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns">
        <div class="column">
          {{ customerId }}
        </div>
        <div class="column" style="text-align: end;">
          <b-button type="is-primary" @click="newDynamicFields">
            Add
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-table :data="dynamicFields">
            <b-table-column v-slot="props" label="Name">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" label="Type">
              {{ props.row.type }}
            </b-table-column>
            <b-table-column v-slot="props" label="Display name">
              {{ props.row.displayName }}
            </b-table-column>
            <b-table-column v-slot="props" label="Translate ref">
              {{ props.row.translateRef }}
            </b-table-column>
            <b-table-column v-slot="props" label="Active">
              {{ props.row.active }}
            </b-table-column>
            <b-table-column v-slot="props">
              <b-button @click="edit(props)">
                Edit
              </b-button>
            </b-table-column>
          </b-table>
        </div>
      </div>
      <b-button type="is-primary" outlined @click="pushData">
        Save
      </b-button>
    </div>
  </section>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'DynamicFields',
  data: function () {
    return {
      customerId: '',
      dynamicFields: [],
      isModalActive: false,
      editIndex: 0,
      types: ['checkbox', 'select', 'text']
    }
  },
  mounted () {
    this.customerId = this.$route.params.customer_id
    this.getData()
  },
  methods: {
    async getData () {
      const builder = this.$gql.query({
        operation: 'dynamicFields',
        fields: [
          {
            dynamicFields: [
              'fieldId',
              'customerCode',
              'name',
              'type',
              'displayName',
              'translateRef',
              'active',
              'selectFields { fieldId  label  value }'
            ]
          }
        ],
        variables: {
          customerId: { type: 'ID!', value: this.customerId }
        }
      })

      const result = await this.$apollo.query({
        query: gql`
            ${builder.query}
          `,
        variables: builder.variables
      })
      this.dynamicFields = result.data.dynamicFields.dynamicFields
    },
    async pushData () {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($dynamicFields: [DynamicFieldsInput]) {
            dynamicFieldsUpdate(dynamicFields: $dynamicFields)
        }`,
        variables: {
          dynamicFields: this.dynamicFields
        }
      })
      this.getData()
    },
    edit (row) {
      this.isModalActive = true
      this.editIndex = row.index
    },
    newDynamicFields () {
      this.dynamicFields.push({
        customerCode: this.customerId,
        name: '',
        type: '',
        displayName: '',
        translateRef: '',
        active: true,
        selectFields: []
      })
      this.edit({ index: this.dynamicFields.length - 1 })
    }
  }
}
</script>
