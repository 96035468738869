<template>
  <section>
    <div class="container modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          First time Azure user connection
        </p>
      </header>
      <div class="modal-card-body">
        <form
          id="externalLogin"
          novalidate="true"
          @submit.prevent="onLogin()"
        >
          <b-field>
            <b-input
              ref="externalLogin"
              v-model="username"
              name="username"
              placeholder="Usern email"
              type="text"
              icon="user"
            />
          </b-field>
          <b-field>
            <b-input
              v-model="password"
              name="password"
              password-reveal
              placeholder="Password"
              type="password"
              icon="lock"
            />
          </b-field>
          <button
            :class="{ 'is-loading': isLoading }"
            class="button is-primary is-fullwidth"
            type="submit"
          >
            Activate
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import * as User from '@/services/v1/user'
export default {
  data () {
    return {
      isLoading: false,
      username: '',
      password: ''
    }
  },
  methods: {
    onLogin: async function () {
      this.isLoading = true
      try {
        await User.activateUser({ username: this.username, password: this.password })
        this.$buefy.toast.open({ message: 'Success' + '<br> Regisered new user', duration: 8000, type: 'is-primary' })
        this.$emit('on-close')
      } catch (error) {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: error.message,
          duration: 5000,
          hasIcon: true
        })
      }
      this.isLoading = false
    }
  }
}
</script>
