<template>
  <section class="section">
    <div class="container">
      <Header v-bind="$route.meta" />
      <div class="columns">
        <div class="column">
          <ag-grid-vue
            style="width: 100%; height: 700px;"
            class="ag-theme-balham"
            :grid-options="gridOptions"
            :pagination="true"
            :side-bar="['columns']"
            :cache-overflow-size="0"
            :multi-sort-key="'ctrl'"
            :get-child-count="getChildCount"
            :get-context-menu-items="getContextMenuItems"
            :modules="modules"
            @grid-ready="gridReady"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sortAndFilterMixins from '@/mixins/agGridSortAndFilter'
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  name: 'Customers',
  mixins: [sortAndFilterMixins],
  data: function () {
    return {
      customers: [],
      totalCount: 0,
      modules: AllModules,
      gridOptions: {
        rowModelType: 'serverSide',
        serverSideStoreType: 'partial',
        cacheBlockSize: 20,
        paginationPageSize: 20,
        enableRangeSelection: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: false,
          resizable: true
        },
        columnDefs: [
          { headerName: this.$t('pages.customers.grid.id'),
            field: 'id',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.customers.grid.name'),
            field: 'name',
            filter: 'agTextColumnFilter',
            width: 260,
            sortable: true,
            sort: 'asc',
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.customers.grid.country'),
            field: 'country',
            filter: 'agTextColumnFilter',
            width: 130,
            sortable: true,
            filterParams: { suppressAndOrCondition: true } },
          { headerName: this.$t('pages.customers.grid.city'),
            field: 'city',
            filter: 'agTextColumnFilter',
            width: 200,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          },
          { headerName: this.$t('pages.customers.grid.address'),
            field: 'address',
            filter: 'agTextColumnFilter',
            width: 200,
            sortable: true,
            filterParams: { suppressAndOrCondition: true }
          }
        ]
      }
    }
  },
  methods: {
    gridReady: function (params) {
      const dataSource = {
        getRows: async (params) => {
          const groupSummary = this.getGroups(params.request)

          let columns = groupSummary.filter(e => e.isHere === true).map(e => e.column.toString())
          if (!columns.length) {
            columns = this.gridOptions.columnDefs.map(colDef => colDef.field)
          }
          const builder = this.$gql.query({
            operation: 'customers',
            fields: ['totalCount', { edges: [{ node: [{ aggregate: [{ count: ['id'] }, ...this.getAggregate(params.request)] }, ...columns] }] }],
            variables: {
              skip: { type: 'Int', value: params.request.startRow },
              limit: { type: 'Int', value: params.request.endRow },
              sort: { type: '[sortInputCustomer]', value: this.getSorts(params.request) },
              filter: { type: 'filterInputCustomer', value: this.getFilters(params.request) },
              group: { type: 'Boolean', value: !!groupSummary.find(e => e.isHere === true) }
            }
          })

          const result = await this.$apollo.query({
            fetchPolicy: 'no-cache',
            query: gql`${builder.query}`,
            variables: builder.variables })

          const customersResult = result.data.customers
          this.totalCount = customersResult.totalCount
          this.customers = customersResult.edges.map(e => e.node)

          params.successCallback(this.customers, this.totalCount)
        }
      }
      params.api.setServerSideDatasource(dataSource)
    },
    getChildCount: function (data) {
      if (data) {
        return data.aggregate.count.id
      }
    },
    getContextMenuItems (params) {
      const selectedPlant = params.node.data
      let customMenu = []
      customMenu.push({
        name: this.$t('sidebar.production'),
        action: () => {
          this.$router.push({ name: 'DynamicFields', params: { customer_id: selectedPlant.id } })
        },
        icon: '<i class="fas fa-fw fa-industry" ></i>'
      })

      return customMenu
    }
  }

}
</script>
