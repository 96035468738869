<template>
  <div class="modal-card" style="width: auto; min-height: 70vh">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Add new device
      </p>
    </header>
    <section class="modal-card-body">
      <div class="mb-5">
        <b-field label="Serial number(Optional)">
          <b-input v-model="device.serialNumber" />
        </b-field>
      </div>
      <div class="mb-5">
        <b-field label="Type">
          <b-radio
            v-model="type"
            native-value="plant"
          >
            Plant
          </b-radio>
          <b-radio
            v-model="type"
            native-value="customer"
          >
            Customer
          </b-radio>
          <b-radio
            v-model="type"
            native-value="producer"
          >
            Producer
          </b-radio>
        </b-field>
      </div>
      <div class="mb-5">
        <div v-if="type === 'plant'">
          <b-field label="Plant">
            <multiselect
              v-model="assginedTo"
              :options="$lodash.orderBy(plants, ['name'])"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick plant..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
        <div v-if="type === 'customer'">
          <b-field label="Customer">
            <multiselect
              v-model="assginedTo"
              :options="customers"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick customer..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
        <div v-if="type === 'producer'">
          <b-field label="Producer">
            <multiselect
              v-model="assginedTo"
              :options="$lodash.orderBy(plants, ['name'])"
              :multiple="false"
              :close-on-select="true"
              placeholder="Pick producer..."
              :custom-label="getLabel"
              track-by="id"
            />
          </b-field>
        </div>
      </div>
      <div v-if="qrCodeGenerated" style="min-height: 150px">
        <canvas id="qrcode" class="block" />
        <b-button icon-left="download" @click="downloadQRCode" />

        <p>New device registration code: <strong>{{ device.registrationCode }}</strong></p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons ml-auto">
        <b-button type="is-info" :disabled="!type ||!assginedTo" @click="generateCode">
          Generate code
        </b-button>
        <b-button type="is-primary" :loading="isLoading" :disabled="!device.registrationCode" @click="submit">
          Submit
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import crypto from 'crypto'
import QRCode from 'qrcode'
import { saveAs } from 'file-saver'
import Device from '@/services/v3/device'

export default {
  props: {
    plants: {
      default: () => [],
      type: Array
    },
    customers: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      isLoading: false,
      type: 'plant',
      qrCodeGenerated: false,
      assginedTo: '',
      device: {
        serialNumber: '',
        registrationCode: '',
        plantId: '',
        customerId: '',
        producerId: ''
      }
    }
  },
  watch: {
    type () {
      this.qrCodeGenerated = false
      this.assginedTo = ''
    },
    assginedTo () {
      this.qrCodeGenerated = false
    }
  },
  methods: {
    getLabel ({ id, name }) {
      return `${id} - ${name}`
    },
    generateCode () {
      const currentTimestamp = new Date().toISOString()
      const randomValue = Math.random().toString()
      const combinedInput = this.assginedTo.id + currentTimestamp + randomValue
      const hash = crypto.createHash('sha256').update(combinedInput).digest('hex')
      this.device.registrationCode = hash.slice(0, 6)
      this.qrCodeGenerated = true // Ensure canvas is rendered
      this.$nextTick(() => {
        this.generateQRCode(this.device.registrationCode)
      })
    },
    generateQRCode (text) {
      const canvas = document.getElementById('qrcode')
      QRCode.toCanvas(canvas, text, (error) => {
        if (error) this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      })
    },
    downloadQRCode () {
      const canvas = document.getElementById('qrcode')
      canvas.toBlob(blob => saveAs(blob, `${this.assginedTo.id}_${this.device.registrationCode}.png`))
    },
    async submit () {
      switch (this.type) {
        case 'plant':
          this.device.plantId = this.assginedTo.id
          break
        case 'customer':
          this.device.customerId = this.assginedTo.id
          break
        case 'producer':
          this.device.producerId = this.assginedTo.id
          break
      }
      try {
        this.isLoading = true
        await Device.createByRigstrationCode(this.device)
        this.$buefy.toast.open({ type: 'is-primary', message: 'Done !', duration: 3000 })
        this.$emit('on-close')
      } catch (error) {
        this.$buefy.toast.open({ type: 'is-danger', message: error.message || error, duration: 3000 })
      }
      this.isLoading = false
    }
  }
}

</script>
