<template>
  <section class="section">
    <b-loading :active.sync="isLoading" is-full-page />
    <div class="container is-fluid">
      <div class="columns is-multiline">
        <div class="column is-narrow">
          <h1 class="title">
            Users
          </h1>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-9">
          <div class="field">
            <div class="control buttons">
              <button class="button" @click="isCreating = true">
                <b-icon icon="plus" size="is-small" />
                <span> Add user</span>
              </button>
              <button class="button is-info" @click="isActivatingUser = true">
                <b-icon icon="rotate" size="is-small" />
                <span> Activate user</span>
              </button>
            </div>
          </div>
        </div>

        <ag-grid-vue
          style="width: 100%; height: 1000px;"
          class="ag-theme-alpine"
          :row-data="users"
          :grid-options="gridOptions"
          :pagination="true"
          :pagination-page-size="20"
          :multi-sort-key="'ctrl'"
          :modules="modules"
          row-selection="single"
          @grid-ready="gridReady"
        />
      </div>
    </div>
    <b-modal :active.sync="isCreating" scroll="keep" has-modal-card>
      <UserForm
        :groups="groups"
        :is-submitting="isSubmitting"
        :roles="roles"
        @submit="submitCreateUser"
      />
    </b-modal>
    <b-modal :active.sync="isEditing" scroll="keep" has-modal-card>
      <UserForm
        :groups="groups"
        :editable-user="editableUser"
        :is-submitting="isSubmitting"
        :roles="roles"
        @submit="submitUpdateUser"
      />
    </b-modal>

    <b-modal :active.sync="isActivatingUser" has-modal-card>
      <ActiveUserForm @on-close="close" />
    </b-modal>
  </section>
</template>

<script>
import UserForm from './user-form'
import ActiveUserForm from './active-user-form.vue'
import Role from '@/services/v2/role'
import * as Group from '@/services/v1/group'
import * as User from '@/services/v1/user'
import gql from 'graphql-tag'
import { AllModules } from '@ag-grid-enterprise/all-modules'

export default {
  components: {
    UserForm,
    ActiveUserForm
  },
  data () {
    return {
      modules: AllModules,
      users: [],
      groups: [],
      isCreating: false,
      isEditing: false,
      isActivatingUser: false,
      editableUser: {},
      isSubmitting: false,
      isLoading: true,
      roles: [],
      filters: this.$store.state.auth.user.role.name === 'Hub Admin' || this.$store.state.auth.user.role.name === 'HUB Super Admin RESTRICTED' ? ['END USER', 'CUSTOMER', 'HUB', 'PFT'] : ['END USER'],
      gridApi: null,

      gridOptions: {
        masterDetail: true,
        getContextMenuItems: this.gridActions,
        suppressAggFuncInHeader: true,
        defaultColDef: {
          floatingFilter: true,
          enableValue: true,
          enableRowGroup: true,
          enablePivot: true,
          sortable: true,
          filter: true,
          resizable: true

        },

        columnDefs: [
          {
            headerName: 'Username',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'username'
          },
          {
            headerName: 'Display name',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'displayName'
          },
          {
            headerName: 'Email',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'email'
          },
          {
            headerName: 'Provider',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'provider',
            cellClass: (params) => {
              return params.value === 'aad' ? 'rag-yellow' : ''
            }
          },
          {
            headerName: 'Role',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'role',
            valueGetter: function (params) {
              return params?.data?.role?.name || ''
            }
          },
          {
            headerName: 'Group',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'group',
            valueGetter: function (params) {
              return params?.data?.group?.name || ''
            }
          },
          {
            headerName: 'Last connection',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'lastConnection',
            valueGetter: (params) => {
              return params.data.lastConnection ? this.$dayjs(params.data.lastConnection).format('DD/MM/YYYY') : 'Never'
            },
            cellClass: (params) => {
              return params?.value === 'Never' ? 'rag-red' : ''
            }
          },
          {
            headerName: 'Type',
            filter: 'agTextColumnFilter',
            filterParams: { suppressAndOrCondition: true },
            field: 'type',
            cellClass: (params) => {
              if (params.value) {
                switch (params.value) {
                  case 'HUB':
                    return 'rag-green'
                  case 'CUSTOMER':
                    return 'rag-info'
                  case 'END USER':
                    return 'rag-purple'
                  case 'PFT':
                    return 'rag-orange'
                  default:
                    return ''
                }
              } else {
                return null
              }
            }
          }
        ]
      }
    }
  },
  mounted: async function () {
    try {
      this.loadUsers()
      this.roles = await Role.getRoles()
      this.groups = await Group.getAll(this.groupsFilters)
    } catch (error) {
    }
  },
  methods: {
    gridReady: function (gridApi) {
      try {
        gridApi.api.sizeColumnsToFit()
        this.gridApi = gridApi
      } catch (e) {

      }
    },
    loadUsers: async function () {
      try {
        const builder = this.$gql.query([
          {
            operation: 'groups',
            fields: ['totalCount', { edges: [{ node: ['id', 'name', 'type', { customers: ['id', 'name'], plants: ['id', 'name'] }] }] }]
          },
          {
            operation: 'users',
            fields: ['totalCount', { edges: [{
              node: [
                'id', 'username', 'displayName', 'email', 'lastConnection', 'provider', 'type',
                {
                  group: ['id', 'name', 'type']
                },
                {
                  role: ['id', 'name']
                }]
            }]
            }],
            variables: {
              filter: { type: 'filterInputUser', value: { type: { in: this.filters } } }
            }
          }
        ])

        const result = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: gql`${builder.query}`,
          variables: builder.variables })

        this.groups = result.data.groups.edges.map(e => e.node)
        this.users = result.data.users.edges.map(e => e.node)
        this.isLoading = false
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
    },
    deleteUser (userId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting user',
        message: 'Are you sure you want to <b>delete</b> this user? This action cannot be undone.',
        confirmText: 'Delete User',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await User.deleteUser(userId)
            await this.loadUsers()
          } catch (error) {
            this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
          }
        }
      })
    },
    showEditForm (user) {
      this.isEditing = true
      this.editableUser = user
    },
    async close () {
      this.isActivatingUser = false
      await this.loadUsers()
    },
    submitUpdateUser: async function (user) {
      try {
        this.isSubmitting = true
        await User.updateUser(
          user.id,
          user.username,
          user.displayName,
          user.group?.id,
          user.email,
          user.password,
          user.role.id,
          user.type
        )
        this.isEditing = false
        await this.loadUsers()
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
      this.isSubmitting = false
    },
    submitCreateUser: async function (user) {
      try {
        this.isSubmitting = true
        await User.create(
          user.username,
          user.displayName,
          user.group.id,
          user.password,
          user.email,
          user.role.id,
          user.type
        )
        this.isCreating = false
        await this.loadUsers()
      } catch (error) {
        this.$buefy.toast.open({ message: error.message || error, type: 'is-danger' })
      }
      this.isSubmitting = false
    },
    gridActions: function (params) {
      let customMenu = []
      const genericMenu = [
        'copy',
        'copyWithHeaders',
        'separator',
        'export'
      ]
      customMenu.push({
        name: 'Update',
        action: () => { this.showEditForm(params.node.data) },
        icon: '<i class="fas fa-fw fa-edit" ></i>'
      })
      if (params.node && params.node.data) {
        if (params.node.data.provider !== 'aad') {
          customMenu.push({
            name: 'Delete',
            action: () => {
              this.deleteUser(params.node.data?.id)
            },
            icon: '<i class="fas fa-fw fa-trash" ></i>'
          })
        }
      }
      const result = [...customMenu, ...genericMenu]
      return result
    }
  }
}
</script>

<style>

</style>
