import axios from 'axios'
import interceptorsSetup from '@/helpers/interceptors'

const baseURL = process.env.VUE_APP_API_URL_V3 + '/devices'

const devices = axios.create({
  baseURL
})

interceptorsSetup(devices)

export default class Device {
  static async createByRigstrationCode (newDevice) {
    const { data: result } = await devices.post('/', newDevice)
    return result
  }

  static async resetDevice (code) {
    const { data: result } = await devices.get(`/${code}/reset`)
    return result
  }
}
